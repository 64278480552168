<template>
  <dashboard-layout>
    <template #content>
      <page-header
        name="analytics"
        icon="chart-pie"
      />

      <b-tabs class="has-navbar-dashboard">
        <b-tab-item label="user activity">
          <section class="section">
            <div class="container">
              <users-activity-table
                :loading="isLoading"
                :users-activity="usersActivity"
              />
            </div>
          </section>
        </b-tab-item>

        <b-tab-item
          disabled
          label="pageviews"
        >
          <section class="section">
            <div class="container">
              <page-views-time-series-chart />
            </div>
          </section>

          <section class="section">
            <div class="container">
              <page-views-statistics />
            </div>
          </section>

          <section class="section">
            <div class="container">
              <page-views-table
                :pages="pages"
                :loading="isLoading"
              />
            </div>
          </section>
        </b-tab-item>
      </b-tabs>
    </template>
  </dashboard-layout>
</template>

<script>
import { mapGetters } from 'vuex';

import { PageHeader } from '@/components/Shared';
import {
  PageViewsStatistics,
  PageViewsTable,
  PageViewsTimeSeriesChart,
  UsersActivityTable,
} from '@/components/Analytics';

export default {

  name: 'Analytics',

  components: {
    DashboardLayout: () => import('../layouts/DashboardLayout.vue'),
    PageHeader,
    PageViewsStatistics,
    PageViewsTable,
    PageViewsTimeSeriesChart,
    UsersActivityTable,
  },

  data: () => ({
    isLoading: true,
  }),

  computed: mapGetters({
    pages: 'Analytics/getPages',
    usersActivity: 'Analytics/getUsersActivity',
  }),

  async created() {
    try {
      await this.$store.dispatch(
        'Analytics/fetchUsersActivity',
        localStorage.getItem('organization_id'),
      );
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

};
</script>

<style lang="css" scoped>
</style>
