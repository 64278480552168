<template>
  <div class="columns">
    <div class="column is-one-quarter">
      <div class="content">
        <h3 class="subtitle has-text-primary">
          Pageviews
        </h3>
        <h1 class="title has-text-info">
          1,164
        </h1>
        <highcharts :options="pageviews" />
      </div>
    </div>

    <div class="column">
      <div class="content">
        <h3 class="subtitle has-text-primary">
          Avg. Time on Page
        </h3>
        <h1 class="title has-text-info">
          01:33
        </h1>
        <highcharts :options="pageviews" />
      </div>
    </div>

    <div class="column">
      <div class="content">
        <h3 class="subtitle has-text-primary">
          Bounce Rate
        </h3>
        <h1 class="title has-text-info">
          55%
        </h1>
        <highcharts :options="pageviews" />
      </div>
    </div>

    <div class="column">
      <div class="content">
        <h3 class="subtitle has-text-primary">
          Pages per Session
        </h3>
        <h1 class="title has-text-info">
          5
        </h1>
        <highcharts :options="pageviews" />
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';

export default {

  name: 'PageViewsStatistics',

  components: {
    highcharts: Chart,
  },

  data: () => ({
    pageviews: {
      chart: {
        type: 'area',
        height: '50%',
      },

      title: {
        text: null,
      },

      xAxis: {
        visible: false,
      },

      yAxis: {
        visible: false,
      },

      plotOptions: {
        area: {
          fillOpacity: 0.5,
        },

        series: {
          marker: {
            enabled: false,
          },
        },

      },
      series: [
        {
          name: 'pageviews',
          data: [150, 25, 200, 175, 125, 130, 75, 100, 80, 50, 100, 200],
          color: '#3B90D3',
        },
      ],
    },
  }),
};
</script>

<style lang="css" scoped>
</style>
