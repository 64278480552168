<template>
  <b-table
    :data="usersActivity"
    :loading="loading"
    paginated
    striped
    checkable
  >
    <b-table-column
      v-slot="props"
      custom-key="avatar"
    >
      <figure class="image is-32x32">
        <img
          style="width:32px; height:32px;"
          class="is-rounded"
          :src="props.row.userPhoto ? props.row.userPhoto : defaultImage"
          :alt="props.row.username"
        >
      </figure>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Name"
      field="name"
    >
      {{ props.row.name }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Team/Department"
      field="userTeamsEntity"
    >
      {{ props.row.userTeamsEntity.name }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="# of Logins"
      field="noOfLogins"
      centered
    >
      {{ props.row.noOfLogins }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Last Login"
      field="lastLogin"
    >
      {{ props.row.lastLogin | date }}
    </b-table-column>

    <template slot="empty">
      <empty-table table-name="user activity" />
    </template>
  </b-table>
</template>

<script>
import defaultImage from '@/assets/images/avatar.svg';

import { EmptyTable } from '@/components/Shared';

export default {

  name: 'UsersActivityTable',

  components: {
    EmptyTable,
  },

  filters: {
    date(value) {
      return new Date(value).toLocaleDateString();
    },
  },

  props: {
    usersActivity: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    defaultImage,
  }),

};
</script>

<style lang="css" scoped>
</style>
