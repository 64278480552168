<template>
  <highcharts :options="chartOptions" />
</template>

<script>
import { Chart } from 'highcharts-vue';

export default {

  name: 'PageViewsTimeSeriesChart',

  components: {
    highcharts: Chart,
  },

  data: () => ({
    chartOptions: {
      chart: {
        type: 'area',
      },

      xAxis: {
        categories: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ],
      },

      plotOptions: {
        area: {
          fillOpacity: 0.5,
        },
      },

      series: [
        {
          name: 'pageviews',
          data: [150, 25, 200, 175, 125, 130, 75, 100, 80, 50, 100, 200],
        },
      ],
    },
  }),

};
</script>

<style lang="css" scoped>
</style>
