<template>
  <b-table
    :data="pages"
    :loading="loading"
    striped
    checkable
  >
    <b-table-column
      v-slot="props"
      label="Page"
      field="page"
    >
      <span class="has-text-info">
        {{ props.row.page }}
      </span>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Pageviews"
      field="pageviews"
      centered
    >
      {{ props.row.pageviews }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="% of Views"
      field="percentageOfViews"
      centered
    >
      {{ props.row.percentageOfViews }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Avg. Time"
      custom-key="averageTime"
    >
      {{ props.row.averageTime }}
    </b-table-column>

    <template slot="empty">
      <empty-table table-name="pages" />
    </template>
  </b-table>
</template>

<script>
import { EmptyTable } from '@/components/Shared';

export default {

  name: 'PageViewsTable',

  components: {
    EmptyTable,
  },

  props: {
    pages: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

};
</script>

<style lang="css" scoped>
</style>
